/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Image from '../shared/Image';

// Style
import '../page-styles/Contact.scss';
import ADRESS from '../images/contact/adress.png';
import PHONE from '../images/contact/phone.png';
import EMAIL from '../images/contact/mail.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Contact() {
  // localisation
  const { t } = useTranslation();

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const {
    title: sectionTitle,
    formTitle,
    formParagraph,
    inputs,
    buttonText,
    adress,
    email,
    phone,
  } = t('contact', {
    returnObjects: true,
  });

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const InputsMap = inputs?.map(({ id, name, placeholder, type }) => (
    <div key={id} className="contact-form-item">
      <label htmlFor={name}>{placeholder}</label>
      <input
        type={type || 'text'}
        placeholder={placeholder}
        className="contact-form__input"
        name={name}
        required
      />
    </div>
  ));

  /* ******************************** RENDERING ******************************* */

  return (
    <PageLayout isTransparent>
      <Seo title={t('title')} />
      <Container fluid>
        <section className="contact bg-white ">
          <span id="contact-section" />
          <div className="container scroll-reveal">
            <h1>{sectionTitle}</h1>
            <Container fluid>
              <Row className="contact-white-bg" justify="between" debug>
                <Col lg={4} md={4} sm={12} className="contact-banner_col" debug>
                  <div className="contact-banner_container">
                    <img className="icon" src={ADRESS} alt="" />
                    <h1 className="contact-banner__paragraph">{adress}</h1>
                  </div>
                </Col>
                <Col lg={3.5} md={3.5} sm={12} className="contact-banner_col">
                  <div className="contact-banner_container">
                    <img className="icon" src={PHONE} alt="" />
                    <h1 className="contact-banner__paragraph">{phone}</h1>
                  </div>
                </Col>
                <Col lg={3.5} md={3.5} sm={12} className="contact-banner_col">
                  <div className="contact-banner_container">
                    <img className="icon" src={EMAIL} alt="" />
                    <h1 className="contact-banner__paragraph">{email}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row align="center">
                <Col lg={6} md={12}>
                  <p className="contact__title">{formTitle}</p>
                  <p className="contect__paragraph">{formParagraph}</p>
                  <form
                    className="contact-form"
                    action="https://formspree.io/f/mjvjrkpe"
                    id="form"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="_subject"
                      value="Contact Fondation Tunisie"
                    />
                    {InputsMap}
                    <div className="contact-form__button">
                      <button type="submit">{buttonText}</button>
                    </div>
                  </form>
                </Col>
                <Col lg={6} md={12}>
                  <div className="contact-img-container">
                    <Image src="contact-img.png" alt="contact image" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ContactPage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
